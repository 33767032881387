import { Box, Divider, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { IMAGES } from "../../assets/images";
import style from "./cancelClassDialog.module.scss";
import { TextInputField, ButtonsListComponent, SingleSelectInputField } from "..";
import { twoButtonList } from "../../utils/reusable-functions/buttonFunction";
import { Form, Formik } from "formik";
import { validateClassCancelSchema } from "../../utils/reusable-functions/data-validation/editClassValidationSchema";

function CancelClassPopupComponent(props: any) {
  const {
    onClose,
    handleButtonClick,
    cancelReason,
    handleSubmitClick,
    cancelClassLoader,
  } = props;

  const initialValues = {
    staticReason: cancelReason[0]?.value || "",
    scheduleId: "",
    otherReason: "",
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validateClassCancelSchema}
        onSubmit={(values) => handleSubmitClick(values)}
        validateOnChange
      >
        {({ errors, touched, handleChange, handleBlur, values }) => {
          return (
            <Form>
              <Box sx={{ width: "100%", p: 3, pb: 4, pt: 2.5 }}>
                <div className={style.cancelClassHeaderContainer}>
                  <Typography className={style.headerText}>Cancel Class</Typography>
                  <Tooltip title="Close">
                    <img
                      src={IMAGES.closeIcon}
                      onClick={onClose}
                      className={style.closeIcon}
                    />
                  </Tooltip>
                </div>
                <Divider sx={{ mb: 4 }} />
                <SingleSelectInputField
                  name="staticReason"
                  label={"Reason to Cancel Class"}
                  listData={cancelReason}
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    values.otherReason = "";
                  }}
                  value={values.staticReason}
                />
                {values.staticReason === "others" && (
                  <TextInputField
                    name="otherReason"
                    label={"If other, Please mention below"}
                    placeholder={"Cancellation Reason"}
                    error={errors.otherReason && touched.otherReason}
                    errorText={errors.otherReason}
                    onBlur={handleBlur}
                    multiline={true}
                    rows={7}
                    stackStyle={{ mt: 3.5 }}
                    onChange={handleChange}
                    value={values.otherReason}
                    maxInputLenth={500}
                  />
                )}
                <ButtonsListComponent
                  isLoading={cancelClassLoader || false}
                  buttonsIndexForLoader={[0]}
                  buttonsArray={twoButtonList("Cancel Class", "Discard", "submit")}
                  onButtonClick={(data: any) => {
                    handleButtonClick(data);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export { CancelClassPopupComponent };
