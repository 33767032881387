import { Box } from "@mui/material";
import * as React from "react";

type CountBoxProps = {
  item: {
    count: string;
    title: string;
  };
};

const CountBox: React.FC<CountBoxProps> = ({ item }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F0F7F8",
        p: 3,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        minWidth: "150px",
      }}
    >
      <Box
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "36px",
          lineHeight: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {item.count}
      </Box>
      <Box
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {item.title}
      </Box>
    </Box>
  );
};

export { CountBox };
