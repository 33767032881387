import { checkTwoArrayObjectsEqual } from "../../../helper-functions";
import { alertConst } from "../../../text-constants";
import { showToast } from "../../toastFunction";

export function modifyEditClassPayload(
  values: any,
  images: any,
  copyData: any,
  setMsg: any
) {
  const formdata = new FormData();
  if (!checkIfNoChangesInPayload(values, copyData, images)) {
    if (images.coverImage?.binaryFile) {
      formdata.append("classImages", images.coverImage.binaryFile, "0.png");
    }
    images.otherImages?.length > 0 &&
      images.otherImages.forEach((otherImage: any, otherImgIndex: number) =>
        otherImage.binaryFile
          ? formdata.append(
              "classImages",
              otherImage.binaryFile,
              `${otherImgIndex + 1}.png`
            )
          : formdata.append(`otherImages[${otherImgIndex}][url]`, otherImage.url)
      );

    const tagsList = getPayloadTags(
      values.mainCategoryId,
      copyData.taggings,
      values.tagsForClass
    );

    tagsList.forEach((tag: any) => {
      formdata.append(tag.name, tag.data);
    });

    values.aboutClass !== copyData.classDesc &&
      formdata.append("classDesc", values.aboutClass);
    values.whatExplorerBring !== copyData.additionalReqComments &&
      formdata.append("additionalReqComments", values.whatExplorerBring);

    if (checkIfAnyLocationDetailsChanged(values, copyData, images)) {
      formdata.append("classLocation[apartmentNumberOrBuildingName]", values.ApartmentNo);
      formdata.append("classLocation[addressLine1]", values.addressLine1);
      formdata.append("classLocation[cityName]", values.city);
      formdata.append("classLocation[countryId]", values.country);
      formdata.append("classLocation[latt]", copyData.classLocation?.loc?.coordinates[1]);
      formdata.append("classLocation[long]", copyData.classLocation?.loc?.coordinates[0]);
      formdata.append("classLocation[directions]", values.additionalLocationDetails);
      formdata.append(
        "classLocation[locationString]",
        `${values.ApartmentNo}, ${values.addressLine1}, ${values.city}, ${values?.zipCode}, ${values.country}`
      );
      formdata.append("classLocation[makeLocationPublic]", values?.makeLocationPublic);
      formdata.append("classLocation[zipCode]", values?.zipCode);
    }

    images.locationImages?.length > 0 &&
      images.locationImages.forEach((locImage: any, locImgIndex: number) =>
        locImage.binaryFile
          ? formdata.append(
              "classImages",
              locImage.binaryFile,
              `location_${locImgIndex}.png`
            )
          : formdata.append(
              `classLocation[locationImages][${locImgIndex}][url]`,
              locImage.url
            )
      );
    return formdata;
  } else {
    showToast(setMsg, alertConst.NO_CHANGES_FOUND_IN_CLASS, "warning");
  }
}

function getPayloadTags(categoryId: string, copiedTags: any, tagsList: any[] = []) {
  const modifiedTagsArray: any[] = [];
  const isEqual = checkTwoArrayObjectsEqual(tagsList, copiedTags);
  if (!isEqual) {
    tagsList.forEach((eachTag: any, tagIndex: number) => {
      modifiedTagsArray.push({ name: `taggings[${tagIndex}][name]`, data: eachTag.name });
      modifiedTagsArray.push({
        name: `taggings[${tagIndex}][categoryId]`,
        data: categoryId,
      });
      modifiedTagsArray.push({ name: `taggings[${tagIndex}][type]`, data: "others" });
    });
  }
  return modifiedTagsArray;
}

function checkIfAnyLocationDetailsChanged(values: any, copyData: any, images: any) {
  if (
    values.country === copyData.classLocation.countryId &&
    values.city === copyData.classLocation.cityName &&
    values.ApartmentNo === copyData.classLocation.apartmentNumberOrBuildingName &&
    values.addressLine1 === copyData.classLocation.addressLine1 &&
    values.additionalLocationDetails === copyData.classLocation.directions &&
    values.zipCode === copyData.classLocation.zipCode &&
    values.makeLocationPublic.toString() ===
      copyData.classLocation?.makeLocationPublic?.toString() &&
    !checkIsNewImageAdded(images.locationImages, copyData.classLocation?.locationImages)
  ) {
    return false;
  } else {
    return true;
  }
}

function checkIfNoChangesInPayload(values: any, copyData: any, images: any) {
  return (
    !checkIfAnyLocationDetailsChanged(values, copyData, images) &&
    !images.coverImage.binaryFile &&
    !checkIsNewImageAdded(images.otherImages, copyData.otherImages) &&
    values.aboutClass === copyData.classDesc &&
    values.whatExplorerBring === copyData.additionalReqComments &&
    checkTwoArrayObjectsEqual(values.tagsForClass, copyData.taggings) &&
    values.zipCode === copyData.classLocation.zipCode &&
    values.makeLocationPublic.toString() ===
      copyData.classLocation?.makeLocationPublic?.toString()
  );
}

function checkIsNewImageAdded(images: any, copyImage: any) {
  let isTrue = true;
  isTrue = images?.some((img: any) => img.binaryFile);
  if (!isTrue) {
    if (!copyImage) {
      return false;
    } else if (images?.length === copyImage?.length) {
      return false;
    } else {
      return true;
    }
  } else {
    return isTrue;
  }
}
