import { Box, Grid } from "@mui/material";
import * as React from "react";

import { CountBox, DataGridComponent } from "../components";
import style from "./compoundComponents.module.scss";

const CountListAndTableComponent = (props: any): JSX.Element => {
  const { pageState, setPageState, countDataList = [], tableColumns = [] } = props;
  return (
    <>
      <div className={style.countListHolderContainer}>
        {countDataList.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <CountBox item={item} />
          </React.Fragment>
        ))}
      </div>
      <Box sx={{ marginBottom: "50px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DataGridComponent
              columns={tableColumns}
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              page={pageState.page}
              pageSize={pageState.pageSize}
              onPageChange={(page: any) => {
                setPageState({ ...pageState, page });
              }}
              onPageSizeChange={(pageSize: any) => {
                setPageState({ ...pageState, pageSize });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { CountListAndTableComponent };
