import * as React from "react";

import { getSelectedFilters } from "../../../utils/reusable-functions/filterFunctions";
import { checkStringLength } from "../../../utils/reusable-functions/dataLengthCheck";
import {
  getDashboardClassCount,
  getDashboardClassesList,
} from "../../../controllers/dashboardController";
import { CountListAndTableComponent } from "../../../compoundComponents";

const columns = [
  { headerName: "No.", field: "id", width: 70 },
  { headerName: "Class ID", field: "class_id", width: 130 },
  {
    headerName: "Class Title",
    field: "class_title",
    width: 300,
    renderCell: (params: any) => {
      return (
        <p title={params.row.class_title}>{checkStringLength(params.row.class_title)}</p>
      );
    },
  },
  { headerName: "Class Status", field: "class_status", width: 180 },
  { headerName: "Category Name", field: "category_name", width: 200 },
  { headerName: "Guide Name", field: "guide_name", width: 300 },
  {
    headerName: "Guide Email",
    field: "guide_email",
    width: 350,
    renderCell: (params: any) => {
      return (
        <p title={params.row.guide_email}>{checkStringLength(params.row.guide_email)}</p>
      );
    },
  },
  { headerName: "Total Bookings", field: "total_bookings", width: 200 },
  { headerName: "Total Cancellations", field: "total_cancellations", width: 250 },
];

const ClassDataSummaryComponent = (props: any) => {
  const { filterOptions, searchText } = props;
  const [classesMasterData, setClassesMasterData] = React.useState([]);
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    getPaginateData(pageState.page, pageState.pageSize);
    getClassesMasterData();
  }, [pageState.page, pageState.pageSize, filterOptions, searchText]);

  const getPaginateData = async (page: any, pageSize: any) => {
    setPageState({ ...pageState, isLoading: true });

    getDashboardClassesList(
      (response: any) => {
        const data = response.data.map((item: any, i: number) => {
          const id = i + 1 + page * pageSize;
          const classStatus = () => {
            if (item.class_status === "Scheduled") {
              if (item.is_active) {
                return "Published";
              } else {
                return "Unpublished";
              }
            } else {
              return item.class_status;
            }
          };
          return { ...item, id, class_status: classStatus() };
        });
        setPageState({ ...pageState, isLoading: false, data, total: response.count });
      },
      () => {
        console.log("error handler");
      },
      {
        skip: page + 1,
        limit: pageSize,
        module: "dashboard",
        ...getSelectedFilters(filterOptions),
        ...(searchText && { search: searchText }),
      }
    );
  };

  const getClassesMasterData = () => {
    getDashboardClassCount(
      (response: any) => {
        if (response && response.length > 0) {
          setClassesMasterData(response);
        } else {
          setClassesMasterData([]);
        }
      },
      () => {
        console.log("error handler");
      },
      {
        ...getSelectedFilters(filterOptions),
        ...(searchText && { search: searchText }),
      }
    );
  };

  return (
    <CountListAndTableComponent
      countDataList={classesMasterData}
      tableColumns={columns}
      pageState={pageState}
      setPageState={setPageState}
    />
  );
};
export default ClassDataSummaryComponent;
