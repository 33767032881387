import { NavigateFunction } from "react-router-dom";

import { createCouponController } from "../../../controllers/couponsController";
import { showToast } from "../../helper-functions/helperFunction";
import { errorConst } from "../../text-constants";

export const handleSubmitCreateCoupon = (
  values: any,
  navigate: NavigateFunction,
  setMsg: any,
  setIsCreating: any
) => {
  setIsCreating(true);
  const payload = {
    couponTitle: values.title,
    discount: Number(values.discount),
    category: "Generic",
    startDate: new Date(values.startDateTime).setHours(0, 0, 0, 0),
    endDate: new Date(values.endDateTime).setHours(23, 59, 59, 999),
    country: values.countryId,
    status: "Active",
    minAmount: Number(values.minBillAmount),
    maxDiscount: Number(values.maxDiscountAmount),
    description: values.description,
    termsAndConditions: values.termsAndCondtionsList,
    enableEmail: values.enableEmail,
  };

  createCouponController(
    () => {
      showToast(setMsg, `Coupon "${values.title}" created successfully!`, "success");
      setIsCreating(false);
      setTimeout(
        () =>
          navigate("/admin/coupons-listing", {
            state: { tabIndex: 1 },
            replace: true,
          }),
        1500
      );
    },
    (error: any) => {
      setIsCreating(false);
      showToast(
        setMsg,
        error.message || error.data.message || errorConst.UNKNOW_ERROR,
        "error"
      );
    },
    payload
  );
};
