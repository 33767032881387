import * as React from "react";

import { getSelectedFilters } from "../../../utils/reusable-functions/filterFunctions";
import { checkStringLength } from "../../../utils/reusable-functions/dataLengthCheck";
import {
  getDashboardUsersCount,
  getDashboardUsersList,
} from "../../../controllers/dashboardController";
import { CountListAndTableComponent } from "../../../compoundComponents";

const columns = [
  { headerName: "No.", field: "id", width: 50 },
  { headerName: "User ID", field: "user_id", width: 130 },
  {
    headerName: "First Name",
    field: "first_name",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.first_name}>
        {checkStringLength(params.row.first_name ?? "NA", 15)}
      </p>
    ),
  },
  {
    headerName: "Last Name",
    field: "last_name",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.last_name}>
        {checkStringLength(params.row.last_name ?? "NA", 15)}
      </p>
    ),
  },
  {
    headerName: "Email",
    field: "email",
    width: 350,
    renderCell: (params: any) => {
      return (
        <p title={params.row.email}>{checkStringLength(params.row.email ?? "NA")}</p>
      );
    },
  },
  {
    headerName: "Phone",
    field: "phone",
    width: 200,
    renderCell: (params: any) => (
      <p title={params.row.phone}>{checkStringLength(params.row.phone ?? "NA", 20)}</p>
    ),
  },
  {
    headerName: "Username",
    field: "username",
    width: 300,
    renderCell: (params: any) => {
      return (
        <p title={params.row.username}>
          {checkStringLength(params.row.username ?? "NA")}
        </p>
      );
    },
  },
  { headerName: "Role", field: "role", width: 150 },
  { headerName: "Country ID", field: "country_id", width: 120 },
];

const UserDataSummaryComponent = (props: any) => {
  const { filterOptions, searchText } = props;
  const [usersMasterData, setUsersMasterData] = React.useState([]);
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    getPaginateData(pageState.page, pageState.pageSize);
    getUserMasterData();
  }, [pageState.page, pageState.pageSize, filterOptions, searchText]);

  const getPaginateData = async (page: any, pageSize: any) => {
    setPageState({ ...pageState, isLoading: true });

    getDashboardUsersList(
      (response: any) => {
        const data = response.data.map((item: any, i: number) => {
          const id = i + 1 + page * pageSize;
          return { ...item, id };
        });
        setPageState({ ...pageState, isLoading: false, data, total: response.count });
      },
      () => {
        console.log("error handler");
      },
      {
        skip: page + 1,
        limit: pageSize,
        module: "dashboard",
        ...getSelectedFilters(filterOptions),
        ...(searchText && { search: searchText }),
      }
    );
  };

  const getUserMasterData = () => {
    getDashboardUsersCount(
      (response: any) => {
        if (response && response.length > 0) {
          setUsersMasterData(response);
        } else {
          setUsersMasterData([]);
        }
      },
      () => {
        console.log("error handler");
      },
      {
        ...getSelectedFilters(filterOptions),
        ...(searchText && { search: searchText }),
      }
    );
  };

  return (
    <CountListAndTableComponent
      countDataList={usersMasterData}
      tableColumns={columns}
      pageState={pageState}
      setPageState={setPageState}
    />
  );
};
export default UserDataSummaryComponent;
