import * as React from "react";
import { Grid, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import * as components from "../../../components";
import { classesListConst, errorConst } from "../../../utils/text-constants";
import { preCheckDownloadReport } from "../../../utils/reusable-functions/exportDataFunction";
import useMessage from "../../../hooks/customHooks/useMessage";
import {
  getCancelClassReason,
  getClassesList,
} from "../../../utils/reusable-functions/classes/listing/apiFunctions";
import { getSelectedFilters } from "../../../utils/reusable-functions/filterFunctions";
import { IMAGES } from "../../../assets/images";
import { columnHeaders } from "../../../utils/reusable-functions/classes/listing/listingTableHeaders";
import { cancelClassController, updateClassController } from "../../../controllers/classesController";
import { ButtonsListComponent, SimpleDialogue } from "../../../components";
import { twoButtonList } from "src/utils/reusable-functions/buttonFunction";
import { experienceReview, guideReview } from "src/utils/text-constants/guidesConstant";

const ClassesListingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [searchText, setSearchText] = React.useState<string>("");
  const [searchTextForApi, setSearchTextForApi] = React.useState<string>("");
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [isReportDownloading, setIsReportDownloading] = React.useState<boolean>();
  const [isUnpublish, setIsUnpublish] = React.useState({
    id: "",
    classTitle: "",
    isClicked: false,
    scheduleId: "",
  });

  const [pageState, setPageState] = React.useState({
    firstCreatedClassDate: "",
    latestScheduledClassStartTime: "",
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 100,
  });

  const [cancelClassReason, setCancelClassReason] = React.useState<any>();
  const [cancelClassLoader, setCancelClassLoader] = React.useState<boolean>(false);

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [experienceReviewStatus, setExperienceReviewStatus] = React.useState({
    id: "",
    userId: "",
    className: "",
    classId: "",
    status: '',
    isClicked: false
  })

  const [reloadData, setReloadData] =  React.useState<boolean>(false);
  const [isSubmit,setIsSubmit] = React.useState<boolean>(false);

  function changeStatusData(status: string) {
    setExperienceReviewStatus({...experienceReviewStatus,status:status} )
  }

  function clearExperienceReviewStatus() {
    setExperienceReviewStatus({
      id: "",
      userId: "",
      className: "",
      classId:"",
      status: 'Pending',
      isClicked: false
    })
    setIsSubmit(false)
  }

  function showReviewExperience() {
    return (
      <SimpleDialogue
        openPopup={true}
        dialogInlineStyle={{ minWidth: "40%", minHeight: "max-content", padding: 10 }}
      >
        <div style={{ padding: '10px' }}>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }} >
            <h2 style={{ fontSize: 18 }}>{experienceReview.reviewExperience} {experienceReviewStatus.className}</h2>
            <Tooltip title="Close">
              <img
                src={IMAGES.closeIcon}
                onClick={clearExperienceReviewStatus}
                style={{ width: '30px' }}
              />
            </Tooltip>
          </div>
          <components.SingleSelectInputField
            name={'reviewStatus'}
            label=''
            listData={[{ label: "Approve", value: "Approve" }, { label: "Reject", value: "Reject" }]}
            onChange={(e: any) => {
              changeStatusData(e.target?.value)
            }}
            value={experienceReviewStatus.status}
          />
          <ButtonsListComponent
            isLoading={false}
            buttonsIndexForLoader={[0]}
            buttonsArray={twoButtonList(`Submit`, "Cancel")}
            onButtonClick={(data: any) => {
              if (data.label === 'Cancel') {
                clearExperienceReviewStatus()
              } else if (experienceReviewStatus.status === '' || experienceReviewStatus.status === 'PendingApproval') {
                alert(guideReview.selectFromDropdownAlert)
              } else if (data.label === 'Submit' && experienceReviewStatus.status !== '') {
                setIsSubmit(true)
              }
            }}
          />
        </div>
        {submitConfirmationModal()}
      </SimpleDialogue>
    )
  }

  function submitConfirmationModal() {
    const status = experienceReviewStatus.status === 'Reject' ? 'reject' : 'approve'
    return (
      <SimpleDialogue
        openPopup={isSubmit}
        dialogInlineStyle={{ width: '450px', minHeight: "max-content", padding: 20 }}
      >
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }} >
          <h2 style={{ fontSize: 18 }}>Are you sure you want to {status}?</h2>
          <Tooltip title="Close">
            <img
              src={IMAGES.closeIcon}
              onClick={() => setIsSubmit(false)}
              style={{ width: '30px' }}
            />
          </Tooltip>
        </div>
        <ButtonsListComponent
          isLoading={isUpdating || false}
          buttonsIndexForLoader={[0]}
          buttonsArray={twoButtonList("Ok", "Cancel")}
          onButtonClick={ async (data: any) => {
            if (data.label === 'Cancel') {
              setIsSubmit(false)
            } else if (data.label === 'Ok' && experienceReviewStatus.status !== '') {
              // make api call
              const classId = experienceReviewStatus.classId;
              const finalPayload = experienceReviewStatus.status == 'Approve' ? { experienceStatus: 'Approved' } : { experienceStatus: 'Rejected' };
              const experienceStatus = experienceReviewStatus.status == 'Approve' ? 'Approved' : 'Rejected';
              setIsUpdating(true);
              // API call to upade class
              updateClassController(
                () => {
                  setIsUpdating(false);
                  setMsg({
                    show: true,
                    type: "success",
                    msg: `Class ${classId} ${experienceStatus} successfully`,
                    errorType: "default",
                  });
                },
                (error: any) => {
                  setIsUpdating(false);
                  setMsg({
                    show: true,
                    type: "error",
                    msg: error?.data?.message || error?.message || errorConst.UNKNOW_ERROR,
                    errorType: "default",
                  });
                },
                classId,
                finalPayload
              );
              clearExperienceReviewStatus();
              setTimeout(() => {setReloadData(!reloadData)},500)
            }
          }}
        />
      </SimpleDialogue>
    )
  }

  function handleDownloadReport() {
    preCheckDownloadReport(selectedFilters, searchTextForApi, 0, setIsReportDownloading, {
      module: "class",
    });
  }

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      setSearchTextForApi("");
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    setSearchTextForApi("");
  };

  const onApplyOrCancelButtonClick = (data: any, filterData: any) => {
    if (data.id === "apply") {
      setSelectedFilters({ ...filterData });
    }
    setOpenFilter(false);
  };

  function handleCancelPopupButtonClick(button: any) {
    if (button.id === "id-Discard") {
      closeCancelClassPopup();
    }
  }

  function closeCancelClassPopup() {
    setIsUnpublish({ id: "", classTitle: "", isClicked: false, scheduleId: "" });
  }

  function successCancel() {
    setCancelClassLoader(false);
    closeCancelClassPopup();

    getClass();

    setMsg({
      show: true,
      type: "success",
      msg: `Class ${isUnpublish.classTitle} cancelled successfully!`,
      errorType: "default",
    });
  }

  function errorCancel(error: any) {
    setCancelClassLoader(false);
    closeCancelClassPopup();
    setMsg({
      show: true,
      type: "error",
      msg: error.data?.message || errorConst.ERROR_IN_CANCEL_CLASS,
      errorType: "default",
    });
  }

  function getClassCancelObject(cancelClassData: any) {
    return {
      classId: isUnpublish.id,
      scheduleId: isUnpublish.scheduleId,
      ...(cancelClassData?.staticReason !== "others" && {
        reasonId: cancelClassData?.staticReason,
      }),
      reasonDisplay:
        cancelClassData?.staticReason === "others"
          ? cancelClassData.otherReason
          : getCancelReason(cancelClassData),
    };
  }

  function getCancelReason(cancelClassData: any) {
    const filteredData = cancelClassReason.filter(
      (reason: any) => reason.value === cancelClassData.staticReason
    );
    return filteredData[0]?.label;
  }

  React.useEffect(() => {
    getClass();
  }, [pageState.page, pageState.pageSize, selectedFilters, searchTextForApi,reloadData]);

  React.useEffect(() => {
    getCancelClassReason(setCancelClassReason);
  }, []);

  function getClass() {
    getClassesList(setPageState, pageState, setMsg, {
      module: "class",
      ...(searchTextForApi && { search: searchTextForApi }),
      ...getSelectedFilters(selectedFilters),
    });
  }
  return (
    <>
      <Grid container p={1} spacing={2}>
        <Grid item md={12} lg={12} justifyContent="space-between" display={"flex"}>
          <components.LoadingButtonComponent
            isLoading={isReportDownloading}
            buttonLabel={classesListConst.DOWNLOAD_REPORT}
            handleClick={handleDownloadReport}
            endIcon={<img src={IMAGES.downloadReportIcon} alt="downloadreport" />}
          />
          <Stack direction="row" spacing={2}>
            <components.SearchInputField
              value={searchText}
              placeholder={classesListConst.SEARCH_CLASS}
              onChange={handleSearchChange}
              onSearchClick={() => setSearchTextForApi(searchText)}
              onClearClick={handleClearSearch}
            />
            <components.LoadingButtonComponent
              isLoading={pageState.isLoading}
              buttonLabel={classesListConst.FILTER}
              handleClick={() => setOpenFilter((prev: any) => !prev)}
              endIcon={<img src={IMAGES.filterIcon} alt="filter" />}
            />
          </Stack>
        </Grid>
        <Grid item lg={12} md={12}>
          <components.DataGridComponent
            columns={columnHeaders(navigate, setIsUnpublish, setExperienceReviewStatus)}
            rows={pageState.data}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            page={pageState.page}
            pageSize={pageState.pageSize}
            onPageChange={(page: any) => {
              setPageState({ ...pageState, page });
            }}
            onPageSizeChange={(pageSize: any) => {
              setPageState({ ...pageState, pageSize });
            }}
          />
        </Grid>
      </Grid>
      {openFilter && (
        <components.SideDrawer
          openState={openFilter}
          onCloseDrawer={() => setOpenFilter(!openFilter)}
        >
          <components.FilterComponent
            initialDates={
              pageState.firstCreatedClassDate && pageState.latestScheduledClassStartTime
                ? [
                    new Date(pageState.firstCreatedClassDate),
                    new Date(pageState.latestScheduledClassStartTime),
                  ]
                : ""
            }
            filters={selectedFilters}
            setFilters={setSelectedFilters}
            onButtonClick={(data: any, filterData: any) =>
              onApplyOrCancelButtonClick(data, filterData)
            }
          />
        </components.SideDrawer>
      )}
      {isUnpublish.isClicked && (
        <components.CancelClassDialog
          cancelClassLoader={cancelClassLoader}
          openPopup={isUnpublish.isClicked}
          cancelReason={cancelClassReason || []}
          handleButtonClick={(data: any) => handleCancelPopupButtonClick(data)}
          handleSubmitClick={(data: any) => {
            setCancelClassLoader(true);
            cancelClassController(successCancel, errorCancel, getClassCancelObject(data));
          }}
        />
      )}
      {experienceReviewStatus.isClicked && showReviewExperience()}
    </>
  );
};

export default ClassesListingPage;
